import { api, getBlockReport, fetchDataSourceList, fetchReportPdDomainsList } from 'api/report';
import optionData from '@/assets/js/optionData';
import { getReportSubruleList } from 'api/product/subrule';
import { getPidsList } from 'api/product/rankSearch';
import { selectAccountRole } from 'api/account/permission';
import dateUtils from '@/utils/dateutils.js';
import { filterObject } from 'utils/utils';
import { fetchCountryList } from '@/api/DDJ/v3/country';
import qs from 'querystring';
import { mapState, mapActions } from 'vuex';
import { myBlockMixin } from '@/mixins/blockmixins';
import { select_roles } from 'api/user';
import AutoMix from '../../offer/components/autoMix';
import Pagination from '@/components/pagination';
import GroupSelect from '@/components/GroupSelect';

var clickTimer = null;
export default {
  mixins: [myBlockMixin],
  components: {
    AutoMix,
    GroupSelect,
    Pagination,
  },
  data() {
    return {
      offerDetailVisible: false,
      compareSign: false,
      data_source_id_sign: false,
      month_sign: false,
      date_sign: false,
      hour_sign: false,
      offer_id_sign: false,
      affiliate_id_sign: false,
      source_id_sign: false,
      country_sign: false,
      prod_sign: false,
      platform_sign: false,
      aff_sub_sign: false,
      rule_id_sign: false,
      source_manager_sign: false,
      pid_sign: false,
      offer_type_sign: false,
      pb_domain_sign: false,
      event_name_sign: false,
      business_sign: false,
      businessType_sign: false,
      account_manage_sign: false,
      account_type_sign: false,
      offer_payout_type_sign: false,
      af_prt_sign: false,
      offerId: '',
      // 筛选条件
      filter: {
        date: [],
        compareDate: [],
        fromHour: '',
        toHour: '',
        sorting: 'rejectionTotal',
        sortType: 'desc',
        columns: [],
        ruleIds: [],
        sourceManagers: [],
        timezone: '',
        dataSourceIds: '',
        eventName: '',
        businessList: '',
        businessTypes: '',
        accountList: '',
        accountTypes: '',
        important: '',
      },
      dataSourceFilter: false,
      dataSourceFilterForm: {
        country: '',
        os: '',
      },
      // 筛选参数状态
      filterCheckedStatus: [],
      // 列表数据
      list: [],
      // 下拉框列表
      options: {
        timezone: optionData.timezoneOption,
        offerIds: [],
        platform: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'ANDROID',
            value: 'ANDROID',
          },
          {
            label: 'IOS',
            value: 'IOS',
          },
        ],
        subRule: [],
        pidList: [],
        country: [],
        pdDomains: [],
        importants: [
          {
            label: 'important',
            value: 'important',
          },
        ],
      },
      // 加载
      loading: {
        offerIds: false,
        list: false,
        search: false,
      },
      // 设置
      config: {
        fullWidth: document.documentElement.clientWidth,
      },
      pages: {
        total: 0,
        pageParam: {
          page: 1,
          pageSize: 20,
        },
      },
      dataSourceList: [],
      user_roles: '',
      isBDAM: true,
      offerTypeOptions: [
        {
          label: 'rt',
          value: 'rt',
        },
        {
          label: 'ua',
          value: 'ua',
        },
      ],
    };
  },
  created() {
    this.getRoles();
  },
  mounted() {
    //日期默认选择
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() + 3600 * 1000 * 24);
    this.filter.date = [];
    this.filter.date[0] = dateUtils.date2StrYMD(end);
    this.filter.date[1] = dateUtils.date2StrYMD(start);
    // 默认时间
    this.filter.fromHour = '00:00';
    this.filter.toHour = '23:00';
    // 默认时区
    this.filter.timezone = '+00:00';
    // 默认columns
    // this.filter.columns.push('date');
    //sub rule
    this.subRuleList();
    this.managerList();
    this.getPdDomains();
    this.getPids();
    this.getCountry();
    //获取Affiliate
    this.getAffiliateList();
    //获取Source
    this.getSourceIdsList();
    // 获取dataSource数据列表
    this.getDataSourceList();
    this.onChangeCompare();
  },
  computed: {
    ...mapState('affiliate', {
      affiliatesGroupList: (state) => state.affiliatesGroupList,
      affiliateLoading: (state) => state.affiliateLoading,
    }),
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
    }),
    // 计算 InstallRejectRate
    calcInstallRejectRate: () => {
      return (insRejectTotal, installs) => {
        // 规则 如果两个参数都为0的情况 不进行计算
        if (isNaN(insRejectTotal)) {
          insRejectTotal = 0;
        }
        if (isNaN(installs)) {
          installs = 0;
        }
        if (insRejectTotal === 0 && installs === 0) {
          return 0;
        } else {
          return new Number((insRejectTotal / (insRejectTotal + installs)) * 100).toFixed(2);
        }
      };
    },
    comRate: () => {
      return (a, b) => {
        if (isNaN(a)) {
          a = 0;
        }
        if (isNaN(b)) {
          b = 0;
        }
        return a === 0 ? 0 : b === 0 ? 100 : ((a / b) * 100).toFixed(4);
      };
    },
    rate: () => {
      return (a, b) => {
        var c = 0;
        // 规则 如果两个参数都为0的情况 不进行计算
        if (isNaN(a) && isNaN(b)) {
          c = 0;
        } else if (!isNaN(a) && isNaN(b)) {
          if (a > 0) {
            c = 100;
          } else {
            c = 0;
          }
        } else if (a == 0 && b == 0) {
          c = 0;
        } else if (a > 0 && b == 0) {
          c = 100;
        } else {
          c = new Number(((a - b) / b) * 100).toFixed(0);
        }
        if (c > 0) {
          return '+' + c;
        } else {
          return c;
        }
      };
    },
    rateColor: () => {
      return (a, b) => {
        var c = '#606266';
        // 规则 如果两个参数都为0的情况 不进行计算
        if (isNaN(a) && isNaN(b)) {
          c = '#606266';
        } else if (!isNaN(a) && isNaN(b)) {
          if (a > 0) {
            c = '#fc0345';
          } else {
            c = '#606266';
          }
        } else if (a == 0 && b == 0) {
          c = '#606266';
        } else if (a > 0 && b == 0) {
          c = '#fc0345';
        } else if (Number(a) > Number(b)) {
          c = '#fc0345';
        } else if (Number(a) < Number(a)) {
          c = '#299702';
        } else {
          c = '#299702';
        }
        return c;
      };
    },
  },
  watch: {
    filterCheckedStatus: function() {
      this.$nextTick(() => {
        this.$refs.blockReportTableRef.doLayout();
      });
    },
    offer_id_sign: function() {
      this.$nextTick(() => {
        this.$refs.blockReportTableRef.doLayout();
      });
    },
    affiliate_id_sign: function() {
      this.$nextTick(() => {
        this.$refs.blockReportTableRef.doLayout();
      });
    },
    'filter.columns': function(v) {
      this.filter.sorting = v.length > 0 ? 'date' : 'rejectionTotal';
      this.filter.sortType = 'desc';
    },
  },
  methods: {
    // get Affiliate
    ...mapActions('affiliate', ['getAffiliateList']),
    // get Source
    ...mapActions('source', ['getSourceIdsList']),
    detailClick(row) {
      if (clickTimer) {
        window.clearTimeout(clickTimer);
        clickTimer = null;
      }
      var that = this;
      clickTimer = window.setTimeout(function() {
        that.$nextTick(() => {
          const { href } = that.$router.resolve({
            path: '/offer/detail',
            query: {
              offerId: row.offerId,
              country: row.countries,
              os: row.platforms,
            },
          });
          window.open(href, '_blank');
        });
      }, 300);
    },
    detailAutoMixClick(row) {
      this.offerId = row.offerId;
      this.offerDetailVisible = true;
    },
    managerList() {
      selectAccountRole({ roleName: 'Ad Operation' }).then((res) => {
        this.options.sourceManagers = res.result;
      });
    },
    expandRowChange(row, expanded) {
      if (expanded) {
        this.affiliate_id_sign = true;
      } else {
        this.affiliate_id_sign = false;
      }
    },
    load(tree, treeNode, resolve) {
      console.log('dzk');
      this.affiliate_id_sign = true;
      this.offer_id_sign = true;
      let param = {
        fromDate: this.filter.date[0],
        toDate: this.filter.date[1],
      };

      this.pages.pageParam.page = 1;
      if (this.filter.fromHour && this.filter.toHour) {
        param.fromHour = this.filter.fromHour.substring(0, 2);
        param.toHour = this.filter.toHour.substring(0, 2);
      } else {
        this.$message.error('Hour 选项不能为空！');
        return;
      }

      if (this.filter.timezone) {
        param.timezone = this.filter.timezone;
      }
      if (this.date_sign) {
        param.fromDate = tree.date;
        param.toDate = tree.date;
      }
      if (this.offer_id_sign) {
        param.offerIds = tree.offerId;
      }
      if (this.affiliate_id_sign) {
        param.affiliateIds = tree.affiliateId;
      }
      if (this.source_id_sign) {
        param.sourceIds = tree.sourceId;
      }
      if (this.country_sign) {
        param.countries = this.filter.countries;
        param.countries = tree.country;
      }
      if (this.prod_sign) {
        param.pkgs = tree.prod;
      }
      if (this.platform_sign) {
        param.platform = tree.platform;
      }
      if (this.aff_sub_sign) {
        param.affSubs = tree.affSub;
      }
      if (this.rule_id_sign) {
        param.ruleIds = tree.ruleId;
      }
      if (this.source_manager_sign) {
        param.sourceManagers = tree.sourceManager;
      }
      if (this.pid_sign) {
        param.pids = tree.pid;
      }
      if (this.offer_type_sign) {
        param.offerTypes = tree.offerType;
      }
      if (this.data_source_id_sign) {
        param.dataSourceIds = tree.dataSourceId;
      }
      if (this.event_name_sign) {
        param.eventName = tree.eventName;
      }
      if (this.pb_domain_sign) {
        param.pdDomains = tree.pbDomain;
      }
      if (this.business_sign) {
        param.businessList = tree.business;
      }
      if (this.businessType_sign) {
        param.businessTypes = tree.businessType;
      }
      if (this.account_manage_sign) {
        param.accountList = tree.accountManage;
      }
      if (this.account_type_sign) {
        param.accountTypes = tree.accountType;
      }
      if (this.af_prt_sign) {
        param.prtList = tree.afPrt;
      }
      if (this.filter.important) {
        param.important = tree.important;
      }
      if (this.filter.appImportant) {
        param.appImportant = tree.appImportant;
      }
      let newArray = [...this.filterCheckedStatus];
      newArray.push('affiliate_id');
      newArray = newArray.filter((item) => item !== 'compare');
      param.columns = this.filter.columns.concat(newArray).toString();
      param.offerIds = tree.offerId;
      let par = {
        ...this.pages.pageParam,
        ...param,
      };
      getBlockReport(par)
        .then((res) => {
          if (res.code === 200 && res.success == true) {
            const result = res.result.dataList || [];
            result.map((item) => {
              item.hasChildren = false;
            });
            resolve(result);
          } else {
            this.$message.error(res.message);
            resolve([]);
          }
        })
        .catch((error) => {
          this.$message.error('查询失败！' + error.toString());
        });
    },

    getRoles() {
      select_roles().then((res) => {
        this.user_roles = res.result;
        if (
          this.user_roles &&
          (this.user_roles.indexOf('AM') > -1 || this.user_roles.indexOf('BD') > -1)
        ) {
          this.isBDAM = false;
          this.filter.offerTypes = ['ua'];
        }
      });
    },
    // 国家
    getCountry() {
      fetchCountryList().then((res) => {
        this.options.country = res.result;
      });
    },
    getPids() {
      getPidsList().then((res) => {
        this.options.pidList = res.result;
      });
    },
    tableRowClassName({ rowIndex }) {
      if (rowIndex > 0 && rowIndex === this.list.length - 1) {
        return 'bgdfdfdf';
      }
    },
    // 拼接filter数据
    filterDataSet(e, page) {
      let param = {
        fromDate: this.filter.date[0],
        toDate: this.filter.date[1],
      };
      if (this.filter.compareDate && this.filter.compareDate.length > 1) {
        param.compareFromDate = this.filter.compareDate[0];
        param.compareToDate = this.filter.compareDate[1];
      }
      // 重置翻页
      if (page && page !== '') {
        this.pages.pageParam.page = page;
      }
      if (this.filter.fromHour && this.filter.toHour) {
        param.fromHour = this.filter.fromHour.substring(0, 2);
        param.toHour = this.filter.toHour.substring(0, 2);
      } else {
        this.$message.error('Hour 选项不能为空！');
        return;
      }

      if (this.filter.timezone) {
        param.timezone = this.filter.timezone;
      }
      if (this.filter.offerIds) {
        param.offerIds = this.filter.offerIds;
      }
      if (this.filter.affiliateIds) {
        param.affiliateIds = this.filter.affiliateIds.toString();
      }
      if (this.filter.sourceIds) {
        param.sourceIds = this.filter.sourceIds.toString();
      }
      if (this.filter.countries) {
        param.countries = this.filter.countries;
      }
      if (this.filter.pkgs) {
        param.pkgs = this.filter.pkgs;
      }
      if (this.filter.platform) {
        param.platform = this.filter.platform;
      }
      if (this.filter.affSubs) {
        param.affSubs = this.filter.affSubs;
      }
      if (this.filter.sorting) {
        param.sorting = this.filter.sorting;
      }
      if (this.filter.sortType) {
        param.sortType = this.filter.sortType;
      }
      if (this.filter.ruleIds) {
        param.ruleIds = this.filter.ruleIds.toString();
      }
      if (this.filter.sourceManagers.length > 0) {
        param.sourceManagers = this.filter.sourceManagers.toString();
      }
      if (this.filter.pids?.length > 0) {
        param.pids = this.filter.pids.toString();
      }
      if (this.filter.offerTypes?.length > 0) {
        param.offerTypes = this.filter.offerTypes.toString();
      }
      if (this.filter.dataSourceIds?.length > 0) {
        param.dataSourceIds = this.filter.dataSourceIds.toString();
      }
      if (this.filter.eventName) {
        param.eventName = this.filter.eventName;
      }
      if (this.filter.offerName) {
        param.offerName = this.filter.offerName;
      }

      if (this.filter.pdDomains) {
        param.pdDomains = this.filter.pdDomains;
      }
      if (this.filter.businessList) {
        param.businessList = this.filter.businessList;
      }
      if (this.filter.businessTypes) {
        param.businessTypes = this.filter.businessTypes.toString();
      }
      if (this.filter.accountList) {
        param.accountList = this.filter.accountList;
      }
      if (this.filter.accountTypes) {
        param.accountTypes = this.filter.accountTypes.toString();
      }
      if (this.filter.important) {
        param.important = this.filter.important.toString();
      }
      if (this.filter.appImportant) {
        param.appImportant = this.filter.appImportant.toString();
      }

      if (this.filter.offerPayoutType) {
        param.offerPayoutType = this.filter.offerPayoutType.toString();
      }

      if (this.filter.prtList) {
        param.prtList = this.filter.prtList;
      }

      param.columns = this.filter.columns.concat(this.filterCheckedStatus).toString();
      /*if (param.columns.indexOf('source_id') > -1) {
        param.columns = param.columns + ',source_name';
      }*/
      if (e === 'Search') {
        this.searchListClick(param);
      }
      if (e === 'Download') {
        this.exportExcelFileClick(param);
      }
    },
    // 筛选
    searchListClick(param) {
      let par = {
        ...this.pages.pageParam,
        ...param,
      };
      this.loading.list = true;
      this.loading.search = true;
      this.onChangeOther();
      getBlockReport(par)
        .then((res) => {
          this.loading.list = false;
          this.loading.search = false;
          if (res.code === 200 && res.success == true) {
            const result = res.result.dataList || [];
            this.list = [];
            this.list = result;
            this.pages.total = res.result.total.count;
            this.list.push(res.result.total);
          } else {
            this.$message.error(res.message);
            this.list = [];
          }
          this.$nextTick(() => {
            this.$refs.blockReportTableRef.doLayout();
          });
        })
        .catch((error) => {
          this.$message.error('查询失败！' + error.toString());
          this.loading.list = false;
          this.loading.search = false;
        });
    },
    // 导出
    exportExcelFileClick(param) {
      if (param.columns) {
        param = filterObject(param);
        let strParam = qs.stringify(param);
        window.open(`${api.DOWNLOAD_BLOCK_REPORT}?${strParam}`, '_blank');
      } else {
        this.$message.error('columns不能为空！');
      }
    },
    // 更改每页显示数
    handleSizeChange(size) {
      this.pages.pageParam.pageSize = size;
      this.handleCurrentChange(1); // 更改每页显示数以后页码重置为0
    },
    // 点击数字翻页
    handleCurrentChange(currentPage) {
      this.pages.pageParam.page = currentPage;
      this.filterDataSet('Search');
    },
    // 排序监听
    sortChange(column) {
      if (column.prop !== null && column.order !== null) {
        this.filter.sorting = column.prop;
        this.filter.sortType = column.order == 'ascending' ? 'asc' : 'desc';
      } else {
        this.filter.sorting = '';
        this.filter.sortType = '';
      }
      this.filterDataSet('Search');
    },
    // sub rule
    subRuleList() {
      getReportSubruleList().then((res) => {
        this.options.subRule = res.result;
      });
    },
    checkIn() {
      var checked = false;
      if (this.offer_id_sign && !this.affiliate_id_sign) {
        checked = true;
      }
      return checked;
    },

    getDataSourceList(data) {
      fetchDataSourceList(data).then((res) => {
        var dataSource = [];
        for (let i in res.result) {
          dataSource.push({ data_source_id: i, data_source_name: res.result[i] });
        }
        this.dataSourceList = dataSource;
        if (this.dataSourceFilter == true) {
          this.dataSourceFilter = false;
          this.$message.success('data Source 筛选成功');
        }
      });
    },
    dataSourceFilterSubmit() {
      this.getDataSourceList(this.dataSourceFilterForm);
    },
    // 获取pb域名
    getPdDomains() {
      fetchReportPdDomainsList().then((res) => {
        this.options.pdDomains = res.result;
      });
    },
    onChangeCompare() {
      if (this.filterCheckedStatus.indexOf('compare') > -1) {
        this.compareSign = true;
      } else {
        this.compareSign = false;
      }
    },
    formatDate(date) {
      if (!date) return '';
      return date.substring(0, 7); // 获取前7个字符，即 'YYYY-MM'
    },
    onChangeOther() {
      if (this.filterCheckedStatus.indexOf('data_source_id') > -1) {
        this.data_source_id_sign = true;
      } else {
        this.data_source_id_sign = false;
      }
      if (this.filter.columns.indexOf('month') > -1) {
        this.month_sign = true;
      } else {
        this.month_sign = false;
      }
      if (this.filter.columns.indexOf('date') > -1) {
        this.date_sign = true;
      } else {
        this.date_sign = false;
      }
      if (this.filter.columns.indexOf('hour') > -1) {
        this.hour_sign = true;
      } else {
        this.hour_sign = false;
      }
      if (this.filterCheckedStatus.indexOf('offer_id') > -1) {
        this.offer_id_sign = true;
      } else {
        this.offer_id_sign = false;
      }
      if (this.filterCheckedStatus.indexOf('affiliate_id') > -1) {
        this.affiliate_id_sign = true;
      } else {
        this.affiliate_id_sign = false;
      }
      if (this.filterCheckedStatus.indexOf('source_id') > -1) {
        this.source_id_sign = true;
      } else {
        this.source_id_sign = false;
      }
      if (this.filterCheckedStatus.indexOf('country') > -1) {
        this.country_sign = true;
      } else {
        this.country_sign = false;
      }
      if (this.filterCheckedStatus.indexOf('prod') > -1) {
        this.prod_sign = true;
      } else {
        this.prod_sign = false;
      }
      if (this.filterCheckedStatus.indexOf('platform') > -1) {
        this.platform_sign = true;
      } else {
        this.platform_sign = false;
      }
      if (this.filterCheckedStatus.indexOf('aff_sub') > -1) {
        this.aff_sub_sign = true;
      } else {
        this.aff_sub_sign = false;
      }
      if (this.filterCheckedStatus.indexOf('af_prt') > -1) {
        this.af_prt = true;
      } else {
        this.af_prt = false;
      }
      if (this.filterCheckedStatus.indexOf('rule_id') > -1) {
        this.rule_id_sign = true;
      } else {
        this.rule_id_sign = false;
      }
      if (this.filterCheckedStatus.indexOf('source_manager') > -1) {
        this.source_manager_sign = true;
      } else {
        this.source_manager_sign = false;
      }
      if (this.filterCheckedStatus.indexOf('pid') > -1) {
        this.pid_sign = true;
      } else {
        this.pid_sign = false;
      }
      if (this.filterCheckedStatus.indexOf('offer_type') > -1) {
        this.offer_type_sign = true;
      } else {
        this.offer_type_sign = false;
      }
      if (this.filterCheckedStatus.indexOf('pb_domain') > -1) {
        this.pb_domain_sign = true;
      } else {
        this.pb_domain_sign = false;
      }
      if (this.filterCheckedStatus.indexOf('event_name') > -1) {
        this.event_name_sign = true;
      } else {
        this.event_name_sign = false;
      }
      if (this.filterCheckedStatus.indexOf('business') > -1) {
        this.business_sign = true;
      } else {
        this.business_sign = false;
      }
      if (this.filterCheckedStatus.indexOf('businessType') > -1) {
        this.businessType_sign = true;
      } else {
        this.businessType_sign = false;
      }
      if (this.filterCheckedStatus.indexOf('account_manage') > -1) {
        this.account_manage_sign = true;
      } else {
        this.account_manage_sign = false;
      }
      if (this.filterCheckedStatus.indexOf('account_type') > -1) {
        this.account_type_sign = true;
      } else {
        this.account_type_sign = false;
      }
      if (this.filterCheckedStatus.indexOf('offer_payout_type') > -1) {
        this.offer_payout_type_sign = true;
      } else {
        this.offer_payout_type_sign = false;
      }
      if (this.filterCheckedStatus.indexOf('af_prt') > -1) {
        this.af_prt_sign = true;
      } else {
        this.af_prt_sign = false;
      }
    },
  },
};
